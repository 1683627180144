(() => {
    "use strict";
    const resizeMapOverlay = e => {
        handle.parentNode.style.width =
            e.pageX - map.getBoundingClientRect().left + 10 + "px";
    };
    const initMap = () => {
        const antreemImola = [44.3808144, 11.7390542],
            antreemMilano = [45.472912, 9.159883],
            center = [45.275009, 10.4365],
            mapLeaflet = L.map("map").setView(center, 7),
            pin = L.icon({
                iconUrl: "/assets/pin.svg",
                iconSize: [40, 40],
                iconAnchor: [20, 40]
            }),
            altPin = L.icon({
                iconUrl: "/assets/pin-alt.svg",
                iconSize: [40, 40],
                iconAnchor: [20, 40]
            });

        L.tileLayer(
            "https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}",
            {
                maxZoom: 20,
                id: "mapbox.emerald",
                accessToken:
                    "pk.eyJ1Ijoic2JheCIsImEiOiJjajdnNTA4cXIxNWJ5MnFvMnZlMXFkdGY2In0.xmNOwKcxew2JV09YKBl18w"
            }
        ).addTo(mapLeaflet);

        const imolaMarker = L.marker(antreemImola, {
            icon: pin
        }).addTo(mapLeaflet),
            milanoMarker = L.marker(antreemMilano, {
                icon: altPin
            }).addTo(mapLeaflet);

        imolaMarker.on("click", () => {
            mapLeaflet.setView(antreemImola, 17);
        });

        milanoMarker.on("click", () => {
            mapLeaflet.setView(antreemMilano, 17);
        });

        L.control
            .zoom({
                position: "topright"
            })
            .addTo(mapLeaflet);
    };

    const handleContactForm = () => {
        let isSendingForm = false;
    
        $("#contact-form").submit(function (e) {
            if (isSendingForm) return false;
    
            isSendingForm = true;
            $("#contact-form button").attr("disabled", "disabled");
    
            /** @type {HTMLFormElement} */
            const form = e.target;
            const formData = new FormData(form);
            const body = new URLSearchParams(formData);
    
            function onError() {
                isSendingForm = false;
                $("#contact-form button").removeAttr("disabled");
                if (document.querySelector("#modal-contact-form"))
                {
                    const modalHow = document.querySelector("#modal-contact-form");
                    let modalHandler = new ModalHandler(modalHow);
                    modalHandler.showModalWithId("contact-form-error");
                }
            }
    
            fetch(form.action, {
                method: "POST",
                body
            }).then(response => response.json()
            ).then(json => {
    
                isSendingForm = false;
                $("#contact-form button").removeAttr("disabled");
    
                if (json.request === 'ok')
                {
                    if (document.querySelector("#modal-contact-form"))
                    {
                        const modalHow = document.querySelector("#modal-contact-form");
                        let modalHandler = new ModalHandler(modalHow);
                        modalHandler.showModalWithId("contact-form-ok");
                    }
                    $("#contact-form")[0].reset();
                } else
                {
                    onError();
                }
            }).catch(e => {
                onError();
            });
    
            return false;
        });
    };
    
    $(document).on("displayed.page", () => {
        handleContactForm();

        const map = document.querySelector("#map-container");
        if (!map || isMobile()) return;
        const handle = document.querySelector("#handle");

        handle.addEventListener("mousedown", e => {
            map.addEventListener("mousemove", resizeMapOverlay);
        });

        window.addEventListener("mouseup", () => {
            map.removeEventListener("mousemove", resizeMapOverlay);
        });

        handle.addEventListener("touchstart", e => {
            map.addEventListener("touchmove", resizeMapOverlay);
        });

        window.addEventListener("touchend", () => {
            map.removeEventListener("touchmove", resizeMapOverlay);
        });
        initMap();
    
    });
})();
