(() => {
    const string =
`     _          _                           
    / \\   _ __ | |_ _ __ ___  ___ _ __ ___  
   / _ \\ | '_ \\| __| '__/ _ \\/ _ \\ '_ \` _ \\ 
  / ___ \\| | | | |_| | |  __/  __/ | | | | |
 /_/   \\_\\_| |_|\\__|_|  \\___|\\___|_| |_| |_|
 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 Hey, welcome to the console! Need something
 in particular? We also like tinkering with 
 stuff! Want to work with us? Awesome! Click
 here http://${location.host}/IT/team#lavora
 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~`;

    console.log("%c" + string, "color: #e9662b; font-family: monospace");
})();
