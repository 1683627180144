(() => {
    document.addEventListener("DOMContentLoaded", () => {
        if (!document.querySelector("#barba-wrapper")) return;
        var SlideTransition = Barba.BaseTransition.extend({
            start: function() {
                this.loader = document.querySelector("#page-loader");
                Promise.all([this.newContainerLoading, this.fadeOut()]).then(
                    this.fadeIn.bind(this)
                );
            },
            delay: () => {
                return new Promise(function(resolve) {
                    setTimeout(resolve, 600);
                });
            },

            fadeOut: function() {
                this.loader.classList.add("slide-in");
                return this.delay();
            },

            
            fadeIn: function() {
                var el = this.newContainer;

                this.loader.classList.remove("slide-in");
                this.loader.classList.add("slide-out");
                this.done();

                this.delay().then(() => {
                    this.loader.classList.remove("slide-out");
                });
            }
        });
 //
 
        Barba.Dispatcher.on('newPageReady', (currentStatus, oldStatus, container, newPageRawHTML) => {
            if (Barba.HistoryManager.history.length === 1) return;

            const head = document.head;
            const newPageRawHead = newPageRawHTML.match(/<head[^>]*>([\s\S.]*)<\/head>/i)[0];
            const newPageHead = document.createElement('head');

            newPageHead.innerHTML = newPageRawHead;
            const headTags = [
                "meta[name='keywords']",
                "meta[name='description']",
                "meta[property^='og']",
                "meta[name^='twitter']",
                "meta[itemprop]",
                "link[itemprop]",
                "link[rel='prev']",
                "link[rel='next']",
                "link[rel='canonical']",
                "link[rel='alternate']"
            ].join(',');

            const oldHeadTags = [...head.querySelectorAll(headTags)];
            oldHeadTags.map(oldHeadTag => {
                head.removeChild(oldHeadTag);
            });

            const newHeadTags = [...newPageHead.querySelectorAll(headTags)];
            newHeadTags.map(newHeadTag => {
                head.appendChild(newHeadTag);
            });
        });
 
        Barba.Dispatcher.on("transitionCompleted", () => {
            const body = document.querySelector("body");

            const lang = location.pathname.split("/")[1];
            if (lang.length === 2 && lang !== document.documentElement.lang)
                document.documentElement.lang = lang;

            body.classList.remove("menu-open");

            const isHome = /^\/(?:it|en)\/?$/i.test(location.pathname);
            if (isHome) {
                body.classList.add("body-home");
            } else {
                body.classList.remove("body-home");
            }

            $(document).trigger("displayed.page");
        });

        Barba.Pjax.originalPreventCheck = Barba.Pjax.preventCheck;

        const exclude = pathname => {
            return pathname.includes("/blog") || pathname.includes("/author");
        };

        Barba.Pjax.preventCheck = (e, element) => {
            if (element && element.pathname && exclude(element.pathname)) {
                return false;
            } else if (
                element &&
                element.href &&
                element.href.indexOf("#") > -1 &&
                element.href.split("#")[0] !== location.href.split("#")[0]
            )
                return true;
            else return Barba.Pjax.originalPreventCheck(e, element);
        };

        Barba.Pjax.getTransition = function() {
            return SlideTransition;
        };

        Barba.Pjax.start();
    });
})();
