(() => {
    "use strict";

    $(document).on("displayed.page", () => {
        const timeline = document.querySelector("#timeline");
        if (!timeline) return;
        const years = [...timeline.querySelectorAll(".point")],
            goToYear = () => {
                [...timeline.querySelectorAll(".disabled")].forEach(el =>
                    el.classList.remove("disabled")
                );

                if (position === 0)
                    timeline
                        .querySelectorAll(".next")[0]
                        .classList.add("disabled");
                else if (position === years.length - 4)
                    timeline
                        .querySelectorAll(".previous")[0]
                        .classList.add("disabled");

                years.forEach(el => {
                    el.style.transform = "translateX(" + -100 * position + "%)";
                });
            },
            previousYear = () => {
                position += 1;
                if (position + 4 > years.length - 4)
                    position = years.length - 4;

                goToYear(position);
            },
            nextYear = () => {
                position -= 1;
                if (position < 0) position = 0;

                goToYear(position);
            };

        let position = 0;
        goToYear(0);

        timeline
            .querySelectorAll(".next")[0]
            .addEventListener("click", nextYear.bind(this));

        timeline
            .querySelectorAll(".previous")[0]
            .addEventListener("click", previousYear.bind(this));

        let swipeHandler = new GestureHandler(timeline);
        swipeHandler.swipeLeft = previousYear.bind(this);
        swipeHandler.swipeRight = nextYear.bind(this);
    });
})();
