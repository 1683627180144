(() => {
    "use strict";
    let team = [],
        members,
        moving = false;

        
    const translate = what => {
        const roles = {
            Sviluppo: "Develop",
            Comunicazione: "Communication",
            Design: "Design",
            Business: "Business",
            Amministrazione: "Management",
            HR: "HR"
        };

        if (document.documentElement.lang === "it") return what;
        return roles[what] || what;
    };

    const generateElement = member => {
            const element = document.createElement("div"),
                image = document.createElement("img");

            element.classList.add("member");
            element.dataset.name = member.name;

            image.setAttribute("src", member.load);
            image.classList.add("loading");

            const fullImage = new Image();
            fullImage.src = member.image;
            fullImage.onload = () => {
                image.classList.remove("loading");
                image.setAttribute("src", member.image);
            };

            element.appendChild(image);

            return element;
        },
        initTeam = initialTeam => {
            team = initialTeam.filter(member => member.image);
            members = initialTeam.filter(member => member.count).length;

            if (isMobile() && team.length === 0) return;
            const teamNext = document.querySelector("#team-next"),
                teamPrevious = document.querySelector("#team-previous"),
                teamContainer = document.querySelector("#team-slider");

            team.forEach((member, i) => {
                var img = new Image();
                img.src = member.load;

                member.index = i;
            });

            teamContainer.parentNode.classList.remove("hidden-xs-up");

            //shuffle the array
            team = team
                .map(a => ({ sort: Math.random(), value: a }))
                .sort((a, b) => (a.sort > b.sort ? 1 : -1))
                .map(a => a.value);

            while (teamContainer.firstElementChild)
                teamContainer.firstElementChild.remove();

            team.slice(0, 7).forEach((member, i) => {
                const element = generateElement(member);
                element.dataset.position = i;
                if (i === 0) element.classList.add("first");
                else if (i === 6) element.classList.add("last");
                element.dataset.position = i;
                teamContainer.appendChild(element);
            });

            const nextAction = () => {
                    move(5);
                },
                prevAction = () => {
                    move(-5);
                };

            teamNext.addEventListener("click", nextAction.bind(this));
            teamPrevious.addEventListener("click", prevAction.bind(this));

            let swipeHandler = new GestureHandler(teamContainer);
            swipeHandler.swipeLeft = nextAction.bind(this);
            swipeHandler.swipeRight = prevAction.bind(this);

            updateHighlight();
            updateListeners();
        },
        updateListeners = () => {
            const teamContainer = document.querySelector("#team-slider"),
                members = [...teamContainer.querySelectorAll(".member")];

            members.forEach((member, i) => {
                member.removeEventListener("click", moveBy);
                member.addEventListener("click", moveBy);
            });
        },
        moveBy = event => {
            const member = event.target.closest(".member"),
                position = parseInt(member.dataset.position) - 5;

            if (position === 0) return;
            move(position);
        },
        updateHighlight = () => {
            const teamContainer = document.querySelector("#team-slider"),
                highlight = document.querySelector("#team-highlight"),
                selectedElement = document
                    .querySelector("#team-selected")
                    .querySelector(".details"),
                selected = teamContainer.querySelectorAll(".selected")[0],
                currentMember = team[5];

            highlight.classList.remove("hidden-xs-up");

            while (highlight.firstElementChild)
                highlight.firstElementChild.remove();
            while (selectedElement.firstElementChild)
                selectedElement.firstElementChild.remove();

            const link = document.createElement("a"),
                name = document.createElement("h1"),
                role = document.createElement("h2");

            link.setAttribute("href", currentMember.url);
            //link.setAttribute("target", "_blank");
            link.appendChild(name);
            name.innerText = currentMember.name;
            role.innerText = translate(currentMember.role);

            selectedElement.appendChild(link);
            selectedElement.appendChild(role);

            const classList = [...highlight.classList];
            classList.forEach(single => {
                single.includes("balloon-") &&
                    highlight.classList.remove(single);
            });

            highlight.classList.add(
                "balloon-" + ((currentMember.index % 3) + 1)
            );

            if (selected) selected.classList.remove("selected");
            teamContainer.children[5].classList.add("selected");

            const element = document.createElement("div"),
                motto = document.createElement("div");

            motto.classList.add("motto");

            motto.innerText = currentMember.balloon.replace(/<br>/g, "\n");
            element.appendChild(motto);

            highlight.appendChild(element);
        },
        move = moveBy => {
            if (moving) return;
            moving = true;
            if (moveBy < 0) {
                prependElements(moveBy);
            } else {
                appendElements(moveBy);
            }

            updateHighlight();
            window.setTimeout(() => {
                updateListeners();
                moving = false;
            }, 400);
        },
        prependElements = n => {
            team = team.rotate(n);
            const moving = team.slice(0, -n).reverse(),
                teamContainer = document.querySelector("#team-slider");
            let members = [...teamContainer.querySelectorAll(".member")];
            members.forEach(member => {
                member.classList.remove("moving");
                member.style.transform = `translateX(${-moving.length * 100}%)`;
            });

            moving.forEach(member => {
                const element = generateElement(member);
                element.classList.add("before");
                element.style.transform = `translateX(${-moving.length *
                    100}%)`;
                teamContainer.insertBefore(
                    element,
                    teamContainer.firstElementChild
                );
            });

            members = [...teamContainer.querySelectorAll(".member")];
            window.setTimeout(() => {
                members.forEach((member, i) => {
                    member.classList.remove("before");
                    member.classList.remove("first");
                    member.classList.remove("last");
                    if (i === 0) member.classList.add("first");
                    else if (i === 6) member.classList.add("last");
                    else if (i === 5) member.classList.add("selected");

                    member.dataset.position = i;
                    member.classList.add("moving");
                    member.style.transform = `translateX(0%)`;

                    window.setTimeout(() => {
                        member.style.transform = null;
                        if (i > 6) {
                            member.remove();
                        }
                    }, 300);
                });
            }, 10);
        },
        appendElements = n => {
            const toMove = team.slice(0, n);
            team = [...team.slice(n), ...toMove.reverse()];

            const moving = team.slice(7 - n, 7),
                teamContainer = document.querySelector("#team-slider");

            let members = [...teamContainer.querySelectorAll(".member")];
            members.forEach(member => {
                member.classList.remove("moving");
            });

            moving.forEach(member => {
                const element = generateElement(member);
                element.classList.add("before");
                teamContainer.appendChild(element);
            });

            members = [...teamContainer.querySelectorAll(".member")];
            window.setTimeout(() => {
                members.forEach((member, i) => {
                    member.classList.remove("before");
                    member.classList.remove("first");
                    member.classList.remove("last");
                    if (i === moving.length) member.classList.add("first");
                    else if (i === moving.length + 6)
                        member.classList.add("last");
                    else if (i === moving.length + 5)
                        member.classList.add("selected");

                    member.dataset.position = i - moving.length;
                    member.classList.add("moving");
                    member.style.transform = `translateX(${-Math.round(
                        moving.length / 2
                    ) * 100}%)`;

                    window.setTimeout(() => {
                        member.classList.remove("moving");
                        member.removeAttribute("style");
                        if (i < moving.length) {
                            member.remove();
                        }
                    }, 300);
                });
            }, 10);
        };

    $(document).on("displayed.page", () => {
        const teamContainer = document.querySelector("#team-slider"),
            next = document.querySelector("#team-next"),
            prev = document.querySelector("#team-previous");

        if (!teamContainer) return;

        const randomNumber = () => {
            const value = Math.floor(Math.random() * 99),
                number = document.querySelector("#team-number");

            if (!team.length) {
                number.innerText = value;
                requestAnimationFrame(randomNumber);
            } else {
                number.innerText = members;
            }
        };

        randomNumber();

        fetch("/" + document.documentElement.lang + "/api/authors")
            .then(response => {
                return response.json();
            })
            .then(team => {
                initTeam(team, members);
            })
            .catch(err => {
                console.error(err, "what");
            });
    });
})();
