const tether = (window.Tether = require("tether")),
    jQuery = (window.$ = window.jQuery = require("jquery")),
    Popper = (window.Popper = require("popper.js")),
    bootstrap = require("bootstrap"),
    GestureHandler = (window.GestureHandler = require("../gesture-handler.js").GestureHandler),
    ModalHandler = (window.ModalHandler = require("../modal-handler.js").ModalHandler),
    L = (window.L = require("leaflet"));

const Barba = (window.Barba = require("barba.js"));

require("jquery.easing");
require("../polyfill.js");
require("../utils.js");
require("../menu.js");
require("../console-logo.js");
require("../actions.js");
require("../timeline.js");
require("../team.js");
require("../map.js");
require("../slider.js");
require("../barba.js");
require("../blog.js");
require("../tool.js");
require("../dropdown.js");
(() => {
    "use strict";
    document.addEventListener("DOMContentLoaded", () => {
        if (document.querySelector("#blog")) return;
    });
})();
