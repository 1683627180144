const shareThis = require("share-this"),
    hljs = require("highlight.js"),
    twitterSharer = require("share-this/dist/sharers/twitter"),
    linkedInSharer = require("share-this/dist/sharers/linked-in"),
    emailSharer = require("share-this/dist/sharers/email");
(() => {
    "use strict";
    
    $(document).on("DOMContentLoaded", () => {
        let sharer = null;

        if (document.querySelector("#article")) {
            document.querySelectorAll("pre").forEach(el => {
                hljs.highlightBlock(el);
            });

            sharer = shareThis({
                selector: "#article > :not(pre)",
                sharers: [twitterSharer, linkedInSharer, emailSharer],
                attachPosition: document.querySelector(
                    ".article-content-container"
                ),
                popoverClass: "share-this-popover unstyled"
            });

            sharer.init();

            const track = document.querySelector("#track");

            document.addEventListener("wheel", _ => {
                const documentElement = document.documentElement,
                    contentElement = document.querySelector(".content-wrapper"),
                    percent =
                        contentElement.scrollTop /
                        (contentElement.scrollHeight -
                            documentElement.clientHeight) *
                        100;

                track.style.width = percent + "%";
            });
        }
    });
})();
