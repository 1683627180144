exports.GestureHandler = class GestureHandler {
    constructor(gestureArea) {
        this.gesturedZone = gestureArea;

        this.touchstartX = 0;
        this.touchstartY = 0;
        this.touchendX = 0;
        this.touchendY = 0;

        gestureArea.addEventListener(
            "touchstart",
            event => {
                this.touchstartX = event.touches[0].screenX;
                this.touchstartY = event.touches[0].screenY;
            },
            false
        );

        gestureArea.addEventListener(
            "touchend",
            event => {
                this.touchendX = event.changedTouches[0].screenX;
                this.touchendY = event.changedTouches[0].screenY;
                this.handleGesture();
            },
            false
        );

        gestureArea.addEventListener("touchmove", event => {
            if (!isMobile()) event.preventDefault();
        });
    }

    handleGesture() {
        if (this.touchendX < this.touchstartX - 10) {
            //left
            if (this.swipeLeft) {
                this.swipeLeft();
            }
        }

        if (this.touchendX > this.touchstartX + 10) {
            //right
            if (this.swipeRight) {
                this.swipeRight();
            }
        }

        if (this.touchendY < this.touchstartY - 10) {
            //down
            if (this.swipeDown) {
                this.swipeDown();
            }
        }

        if (this.touchendY > this.touchstartY + 10) {
            if (this.swipeUp) {
                this.swipeUp();
            }
        }

        if (this.touchendY == this.touchstartY) {
            //tap
            if (this.tap) {
                this.tap();
            }
        }
    }
};
