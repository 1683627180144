(() => {

    
    "use strict";
    let formId = 0;
    const generateId = function generateUniqueFormId() {
        formId++;
        return formId;
    }
    const currentId = function generateUniqueFormId() {
        return formId;
    }

    let currentResourceData = {};
    const setCurrentResourceData = function (modalHandler, modal, resource) {
        currentResourceData.modal = modal;
        currentResourceData.resource = resource;
        currentResourceData.modalHandler = modalHandler;
    }
    const getCurrentResursData = function(){
        return currentResourceData;
    }

    $(document).on("displayed.page", () => {
        // functions to send the proper Google Analytics event
        function generateFileName(resourceData) {
            let content = '';
            let contentType = resourceData.name;
            if (contentType && contentType.includes('.zip')) {
                contentType = 'Kit';
            }
    
            if (resourceData.resource && resourceData.resource.toLowerCase().includes('improvement')) {
                content = 'Improvement Triggers';
            }
    
            if (resourceData.resource && resourceData.resource.toLowerCase().includes('promises')) {
                content = 'Promises e Potential';
            }
    
            if (resourceData.resource && resourceData.resource.toLowerCase().includes('map')) {
                content = 'Mappa Ecosistema';
            }
    
            if (resourceData.resource && resourceData.resource.toLowerCase().includes('user')) {
                content = 'User Journey Map';
            }
    
            if (resourceData.resource && resourceData.resource.toLowerCase().includes('service')) {
                content = 'Service Blueprint';
            }
    
            if (resourceData.resource && resourceData.resource.toLowerCase().includes('scenario')) {
                content = 'Scenario';
            }
    
            if (resourceData.resource && resourceData.resource.toLowerCase().includes('motivation')) {
                content = 'Motivation matrix';
            }
    
            if (resourceData.resource && resourceData.resource.toLowerCase().includes('canvas')) {
                content = 'Model Canvas';
            }
    
            if (resourceData.resource && resourceData.resource.toLowerCase().includes('negative')) {
                content = 'Negative Brainstorming';
            }

            const category = contentType + ' ' + content;
            return category;
        }

        function sendGAEvent(resourceData){
            const category = generateFileName(resourceData);
            ga('send', 'event', 'ottieni ' + category,'apertura ' + category, 'Campagna Strumenti' );
            // fbq('track', 'Search');
        } 

        if (document.querySelector("#modal-benefit")) {
            const modalBenefit = document.querySelector("#modal-benefit");
            let modalHandler = new ModalHandler(modalBenefit);
            const benefitOrari = document.querySelector("#benefit-orari"),
                benefitPingpong = document.querySelector("#benefit-pingpong"),
                benefitFisioterapista = document.querySelector(
                    "#benefit-fisioterapista"
                ),
                benefitRistoro = document.querySelector("#benefit-ristoro"),
                benefitFormazione = document.querySelector(
                    "#benefit-formazione"
                );

            benefitOrari.addEventListener("click", () => {
                modalHandler.showModalWithId("benefit/orari");
            });

            benefitPingpong.addEventListener("click", () => {
                modalHandler.showModalWithId("benefit/pingpong");
            });

            benefitFisioterapista.addEventListener("click", () => {
                modalHandler.showModalWithId("benefit/fisioterapista");
            });

            benefitRistoro.addEventListener("click", () => {
                modalHandler.showModalWithId("benefit/ristoro");
            });

            benefitFormazione.addEventListener("click", () => {
                modalHandler.showModalWithId("benefit/formazione");
            });
        }

        if (document.querySelector("#modal-partner")) {
            const modalPartner = document.querySelector("#modal-partner");

            let modalHandler = new ModalHandler(modalPartner);
            modalHandler.setDefaultModalWithId("partner/altri-partner");

            const partners = document.querySelectorAll(".partner-button.clickable");

            partners.forEach(partner => {
                partner.addEventListener("click", () => {
                    modalHandler.showModalWithId(`partner/${partner.dataset.partner}`);
                })
            });
        }

        if (document.querySelector("#modal-prize")) {
            const modalPrize = document.querySelector("#modal-prize");

            let modalHandler = new ModalHandler(modalPrize);

            const prizes = document.querySelectorAll(".prizes-button.clickable");

            prizes.forEach(prize => {
                prize.addEventListener("click", () => {
                    console.log(prize.dataset.prize);
                    modalHandler.showModalWithId(`prize/${prize.dataset.prize}`);
                })
            });
        }

        if (
            document.querySelector("#what") &&
            document.querySelector("#modal-what")
        ) {
            const modalWhat = document.querySelector("#modal-what");

            let modalHandler = new ModalHandler(modalWhat);

            const whatSvg = document.querySelector("#what"),
                design = [...whatSvg.querySelectorAll(".design-link")],
                develop = [...whatSvg.querySelectorAll(".development-link")],
                moreButton = document.querySelector("#what-link");

            design.forEach(el => {
                el.addEventListener("click", () => {
                    modalHandler.showModal();
                });
            });

            develop.forEach(el => {
                el.addEventListener("click", () => {
                    modalHandler.showModal();
                });
            });

            if (moreButton) {
                moreButton.addEventListener("click", () => {
                    modalHandler.showModal();
                });
            }
        }

        if (
            document.querySelector("#how") &&
            document.querySelector("#modal-how")
        ) {
            const modalHow = document.querySelector("#modal-how");
            let modalHandler = new ModalHandler(modalHow);
            
            const howSvg = document.querySelector("#how"),
                discover = [...howSvg.querySelectorAll(".how-discover")],
                design = [...howSvg.querySelectorAll(".how-design")],
                deliver = [...howSvg.querySelectorAll(".how-deliver")],
                moreButton = document.querySelector("#how-link");
    
            discover.forEach(el => {
                el.addEventListener("click", () => {
                    modalHandler.showModalWithId("how-discover");
                });
            });

            design.forEach(el => {
                el.addEventListener("click", () => {
                    modalHandler.showModalWithId("how-discover");
                });
            });

            deliver.forEach(el => {
                el.addEventListener("click", () => {
                    modalHandler.showModalWithId("how-discover");
                });
            });

            if (moreButton) {
                moreButton.addEventListener("click", () => {
                    modalHandler.showModalWithId("how-discover");
                });
            }
        }

        if (
            document.querySelector("#modal-how-clients")
        ) {
            const modalHow = document.querySelector("#modal-how-clients");
            let modalHandler = new ModalHandler(modalHow);
            
            const discoverDesignDelivery = document.querySelector("#how-link-clients");

            discoverDesignDelivery.addEventListener("click", () => { 
                modalHandler.showModalWithId("how-discover");
            });  
        }

        const array = [1,2,3,4,5,'Card', 'Template'];
        const selectors=array.filter(val => document.querySelector(`#modal-download-${val}`)); 
        if(selectors.length>0 && !document.querySelector("#modal-download-tools")){
            location.reload();
        }
        if (selectors && document.querySelector("#modal-download-tools")){
            selectors.map(id =>{
                const modalDownload = document.querySelector("#modal-download-tools");
                const modalHandler = new ModalHandler(modalDownload);
                const downloadModal = document.querySelector(`#modal-download-${id}`);
                if(downloadModal){
                    downloadModal.addEventListener("click", (e) => { 
                        const resourceData = e.target.dataset;
                        sendGAEvent(resourceData);
                        setCurrentResourceData(modalHandler,`#modal-download-${id}`,resourceData);
                        modalHandler.showModalWithId(`#modal-download-${id}`, resourceData, generateId, currentId);
                    });  
                }
            });
        }

        if(document.querySelector("#download-again")){
            const buttonRetry = document.querySelector("#download-again");
            buttonRetry.addEventListener("click", ()=> {
                const currentResource = getCurrentResursData();
                currentResource.modalHandler.showModalWithId(currentResource.modal, currentResource.resource, generateId, currentId);
            })
        }
      
    });
})();