(() => {
    "use strict";

    $(document).on("displayed.page", () => {
        const slider = document.querySelector("#slider");
        if (!slider) return;
        const nextSlide = () => {
            let slides = [...slider.querySelectorAll(".slide-community")];

            slides[0].parentNode.appendChild(slides[0].cloneNode(true));
            slides = [...slider.querySelectorAll(".slide-community")];
            slides.forEach(slide => {
                slide.style.transitionDuration = "300ms";
                slide.style.transform = "translateX(calc(-100% - 1em))";
            });

            window.setTimeout(() => {
                let slides = [...slider.querySelectorAll(".slide-community")];

                slides[0].remove();

                slides.forEach(slide => {
                    slide.style.transitionDuration = "0ms";
                    slide.style.transform = "translateX(0)";
                });
            }, 300);
        };

        const nextArrow = slider.querySelectorAll(".slide-community-control")[0];
        const throttled = _.throttle(nextSlide, 310, {
            trailing: false
        });
        nextArrow.addEventListener("click", throttled);

        let slides = [...slider.querySelectorAll(".slide-community")];
        slides.forEach(slide => {
            slide.parentNode.appendChild(slide.cloneNode(true));
        });

        let swipeHandler = new GestureHandler(slider);
        swipeHandler.swipeLeft = throttled.bind(this);
    });
})();
