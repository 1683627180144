(() => {
    "use strict";
    $(document)
        .on("click", ".filter-container label", ({
            target
        }) => {
            const wrapper = $(target).closest(".box-filter");
            const id = wrapper[0].id;
            const isChecked = wrapper.hasClass("checked");
            $(".box-filter").removeClass("checked");
            $(".tool-list section").addClass("hidden");
            if (!isChecked) {
                wrapper.addClass("checked");
                $('.tool-list section[category="'+id+'"]').removeClass("hidden");
            } else {
                $(".tool-list section").removeClass("hidden");
            }
        });

})();