exports.ModalHandler = class ModalHandler {

    constructor(container) {
        this.modalContainer = container;
        this.modals = [...container.querySelectorAll(".modal")];
        this.currentModal = 0;
        this.defaultModal = 0;
        this.scrollDuration = 700;
        this.scrollTimeout = 320;

        [...container.querySelectorAll(".modal-close")].forEach(el => {
            el.addEventListener("click", this.hideModal.bind(this));
        });

        container
            .querySelector(".modal-outside")
            .addEventListener("click", this.hideModal.bind(this));

        [...container.querySelectorAll(".modal-next")].forEach(el => {
            el.addEventListener("click", this.nextModal.bind(this));
        });

        [...container.querySelectorAll(".modal-previous")].forEach(el => {
            el.addEventListener("click", this.previousModal.bind(this));
        });

        container.addEventListener("click", ({ target }) => {
            const pagerDot = target.closest("[data-page-target]");
            if (pagerDot) {
                this.goToModal(+pagerDot.getAttribute("data-page-target"));
            }
        });

        const throttled = _.throttle(this.handleWheel, 1000, {
            trailing: false
        });

        container.addEventListener("wheel", throttled.bind(this));

        window.addEventListener(
            "keydown",
            e => {
                const space = 32,
                    escape = 27,
                    arrowLeft = 37,
                    arrowUp = 38,
                    arrowRight = 39,
                    arrowDown = 40;

                if (escape === e.keyCode) {
                    this.hideModal();
                }

                if (arrowLeft === e.keyCode) {
                    this.previousModal();
                }

                if (arrowRight === e.keyCode) {
                    this.nextModal();
                }

                if ([space, arrowDown, arrowUp].indexOf(e.keyCode) > -1) {
                    e.preventDefault();
                }
            },
            false
        );

        let swipeHandler = new GestureHandler(container);
        swipeHandler.swipeLeft = this.nextModal.bind(this);
        swipeHandler.swipeRight = this.previousModal.bind(this);
    }

    setDefaultModalWithId(id) {
        this.defaultModal = this.modals.findIndex(modal => {
            return modal.id === id;
        });

        if (this.defaultModal < 0) this.defaultModal = 0;
    }

    handleWheel(e) {
        if (this.isScrolling) return false;
        if (!e.deltaX) return;

        var scrolled = Math.max(
            -1,
            Math.min(1, e.wheelDelta || -e.deltaX || -e.detail)
        );

        return scrolled < 0 ? this.nextModal() : this.previousModal(), !1;
    }

    resetScroll() {
        setTimeout(() => {
            this.isScrolling = false;
        }, this.scrollTimeout);
    }

    showModalWithId(id, resourceData, generateId, currentId) {
        let value;
        if (!id) return 0;
        value = this.modals.findIndex(modal => {
            if (resourceData) {
                const formContainer = $(modal).find("#download-form-container");
                const success = $(modal).find("#send-success");
                const fail = $(modal).find("#send-fail");
                if(!formContainer.is(':visible')){
                    formContainer.toggle();
                }
                if(success.is(':visible')){
                    success.toggle();
                }
                if(fail.is(':visible')){
                    fail.toggle();
                }
                if($('#selectDiv').is(':visible'))
                $(modal).find('input[name="resource-name"]').val(resourceData.name);
                const form = $(modal).find("#download-form");
                const downloadButton = $(form).find("#download-form-button");
                if (downloadButton) {
                    const options = ["Card", "Template"];
                    const item = options.includes(resourceData.name) ? resourceData.name : "Kit";

                    $(downloadButton).find("#download-form-button-item").text(item);
                }
                const id= generateId();
                form.on("submit", this.createFormSubmitHandler(resourceData, this, modal, id, currentId));
            }
            return modal.id === id;
        });
        value = value < 0 ? this.defaultModal : value;

        this.goToModal(value, true);
        this.showModal();
    }

    // functions to send the proper Google Analytics event
    generateFileName(resourceData) {
        let content = '';
        let contentType = resourceData.name;

        if (contentType && contentType.includes('.zip')) {
            contentType = 'Kit';
        }

        if (resourceData.resource && resourceData.resource.toLowerCase().includes('improvement')) {
            content = 'Improvement Triggers';
        }

        if (resourceData.resource && resourceData.resource.toLowerCase().includes('promises')) {
            content = 'Promises e Potential';
        }

        if (resourceData.resource && resourceData.resource.toLowerCase().includes('map')) {
            content = 'Mappa Ecosistema';
        }

        if (resourceData.resource && resourceData.resource.toLowerCase().includes('user')) {
            content = 'User Journey Map';
        }

        if (resourceData.resource && resourceData.resource.toLowerCase().includes('service')) {
            content = 'Service Blueprint';
        }

        if (resourceData.resource && resourceData.resource.toLowerCase().includes('scenario')) {
            content = 'Scenario';
        }

        if (resourceData.resource && resourceData.resource.toLowerCase().includes('motivation')) {
            content = 'Motivation matrix';
        }

        if (resourceData.resource && resourceData.resource.toLowerCase().includes('canvas')) {
            content = 'Model Canvas';
        }

        if (resourceData.resource && resourceData.resource.toLowerCase().includes('negative')) {
            content = 'Negative Brainstorming';
        }
        
        const category = contentType + ' ' + content;
        return category;
    }

    sendGAEvent(resourceData) {
        const category = this.generateFileName(resourceData);
        ga('send', 'event', category, 'scarica ' + category, 'Campagna Strumenti');
        // fbq('track', 'CustomizeProduct');
    }

    createFormSubmitHandler(resourceData, context, modal, id, currentId) {
        return function (e) {
            if(id===currentId()){
                e.stopPropagation();
                e.preventDefault();
                const formValues = e.originalEvent.target.elements;
                const requestData = {
                    mail: formValues[2].value,
                    file: resourceData.resource.substring(7), // remove "assets_",
                    fileName: context.generateFileName(resourceData),
                    name: formValues[0].value,
                    last_name: formValues[1].value,
                    company: formValues[3].value,
                    title: formValues[4].value
                }

                if (resourceData.name && resourceData.resource) {
                    const requestURL = `${window.location.origin}/api/download-tools`;
                    const response = fetch(requestURL, { method: 'POST', body: JSON.stringify(requestData) }).catch(error => ({ error }));
                    response.then(result=>{
                    const formContainer = $(modal).find("#download-form-container");
                    const success = $(modal).find("#send-success");
                    const fail = $(modal).find("#send-fail");
                        if (result.error) {
                            formContainer.toggle();
                            fail.toggle();
                            return;
                        } else {
                            success.toggle();
                            formContainer.toggle();
                            return;
                        }
                    });
                    context.sendGAEvent(resourceData);
                }
            }
            
        }
    }

    showModal() {
        this.modalContainer.classList.remove("hidden");
    }

    hideModal() {
        this.modalContainer.classList.add("hidden");
    }

    nextModal() {
        this.goToModal(this.currentModal + 1);
    }

    previousModal() {
        this.goToModal(this.currentModal - 1);
    }

    goToModal(modal, force) {
        if (modal === this.currentModal) return;

        this.isScrolling = setTimeout(() => {
            this.resetScroll();
        }, this.scrollDuration);

        const modals = this.modals;

        if (modals[modal]) {
            modals.forEach(el => {
                el.style.transform = "translateX(" + -100 * modal + "%)";
                el.style.transition = force ? "none" : "all 700ms ease-in-out";
            });

            this.currentModal = modal;
        }
    }
};
