(() => {
    "use strict";
    const toggleMenu = e => {
        const menu = $("#menu");
        const isHidden = menu.hasClass("hidden");
        menu.toggleClass("hidden", !isHidden);
        menu.parent().add(document.body).toggleClass("menu-open", isHidden);
        $("#header, #hamburger").toggleClass("open", isHidden);

        const target = $(".title-wrapper.open");
        if(target) toggleWord({ target });
    };

    const toggleWord = ({ target }) => {
        const wrapper = $(target).closest(".title-wrapper");
        const isOpen = wrapper.hasClass("open");
        $(".title-wrapper").removeClass("open");
        $(".title-wrapper").animate({ scrollLeft: 0 });
        if (!isOpen) {
            wrapper.addClass("open");
        }
        if (target.handled !== true) {
            target.handled = true;
        }
    };


    $(document)
        .on("click", ".menu-wrapper button", toggleWord)
        .on("click", "#navbar-toggler, #menu a", toggleMenu);

})();